@if(this.sharedDataService.refundButton){
    <app-refund></app-refund>
}
@if(this.sharedDataService.refundFail){
    <app-refund-fail></app-refund-fail>
}
@if(this.sharedDataService.refundSuccess){
    <app-refund-success></app-refund-success>
}

<!-- titulo -->
<section class="flex mb-4">
    <span class="flex-1 text-neutral-600 text-2xl font-semibold font-['Inter'] leading-normal">
        Detalle de transacción
    </span>
    @if (loader) {
        <div role="status" class="pr-4">
            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-indigo-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
        </div>

        <div class="text flex gap-2 text-indigo-400 text-[14px] font-semibold font-['Poppins'] leading-none tracking-tight " style="align-items: center;">
            Estamos cargando la información de tu detalle de transacción.
        </div>
    }
</section>

<!-- box Información principal -->
@if (operation_type == 'purchase') {
    <app-card-info cardTitle="Información principal" titleClass="ps-6" >
        <div class="px-6 basis-[20%]">
            <div class="border-e-2 border-indigo-50">
                <div class="box mx-4 py-2">
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                                [theme]="{ width: '100px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" 
                                [theme]="{ width: '180px', height: '20px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <div class="text-slate-800 text-[8px] font-semibold font-['Inter'] leading-3">
                            MONTO DE LA VENTA
                        </div>
                        <div class="amount-color text-slate-800 text-xl font-semibold font-['Inter'] leading-loose">
                            {{ normalizeDataService.currencyFormat(transaction.amount) }}
                        </div>
                    }
                </div>
            </div>
        </div>
        
        
        <div class="flex-1 text-start px-6 basis-[25%]">
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '150px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        NÚMERO DE LA OPERACIÓN:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.operation_number }}
                    </div>
                }
                
            </div>
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '220px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        FECHA Y HORA:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.created_at_time_zone | date:'d \'de\' MMMM y, HH:mm:ss \'hrs\'' }}
                    </div>
                }
                
            </div>
        </div>
        <div class="flex-1 text-start px-6 basis-[35%]">
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '275px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        ID DE TRANSACCIÓN:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.id }}
                    </div>
                }
                
            </div>
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '100px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        CANAL:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.channel }}
                    </div>
                }
                
            </div>
        </div>
        @if (loader) {
            <div class="px-6">
                <div class="text-slate-800 text-[8px] font-semibold font-['Inter'] leading-3">
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '190px', height: '49px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                </div>
                
            </div>
        } @else {
            @if (transaction.status == 'succeeded' || transaction.status == 'SUCCESS') {
                <div class="px-6">
                    <button
                        (click)="handleClick()"
                        class="px-[50px] py-[12px] rounded-xl inline-flex relative flex items-center"
                        [class.bg-zinc-400]="transaction.refunded_amount === transaction.amount"
                        [class.bg-indigo-500]="transaction.refunded_amount !== transaction.amount"
                        [attr.disabled]="transaction.refunded_amount === transaction.amount ? true : null"
                    >
                        <img width="25" src="../../../assets/img/sales/ic-money-back.svg" />
                        <div class="text-white text-xs font-bold font-['Poppins']">Devolución</div>
                    </button>
                </div>
                
            }
        }
    </app-card-info>
} @else {
    <app-card-info cardTitle="Información principal" titleClass="ps-6">
        <!-- <div class="flex-1 px-6 basis-[20%] border-e-2 border-indigo-50">
            <div class="box py-2">
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '100px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '180px', height: '20px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <div class="text-slate-800 text-[8px] font-semibold font-['Inter'] leading-3">
                        MONTO DE LA VENTA
                    </div>
                    <div class="amount-color text-slate-800 text-xl font-semibold font-['Inter'] leading-loose">
                        {{transaction.amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}} CLP
                    </div>
                }
            </div>
        </div> -->

        <div class="flex-1 px-6 basis-[20%] border-e-2 border-indigo-50">
            <div class="box py-2">
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '100px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '180px', height: '22px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <div class="text-slate-800 text-[8px] font-semibold font-['Inter'] leading-3">
                        @if (transaction.operation_type == 'reversal') {
                            MONTO DE REVERSA
                        } @else {
                            MONTO DE DEVOLUCIÓN
                        }
                    </div>
                    <div class="amount-color text-slate-800 text-xl font-semibold font-['Inter'] leading-loose">
                        @if (transaction.operation_type == 'reversal') {
                            {{ normalizeDataService.currencyFormat(transaction.amount) }}
                        } @else {
                            @if (transaction.refunded_amount) {
                                {{ normalizeDataService.currencyFormat(transaction.refunded_amount) }}
                            } @else {
                                {{ normalizeDataService.currencyFormat(transaction.amount) }}
                            }
                        }
                    </div>
                }
            </div>
        </div>
        
        <div class="flex-1 text-start px-6 basis-[40%]">
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '150px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        NÚMERO DE LA OPERACIÓN:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.operation_number }}
                    </div>
                }
                
            </div>
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '220px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        FECHA Y HORA:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.created_at_time_zone | date:'d \'de\' MMMM y, HH:mm:ss \'hrs\'' }}
                    </div>
                }
                
            </div>
        </div>
        <div class="flex-1 text-start px-6 basis-[40%]">
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '275px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        ID DE TRANSACCIÓN:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        {{ transaction.id }}
                    </div>
                }
                
            </div>
            <div>
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '100px', height: '10px', margin: '0px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="inline text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        CANAL:
                    </span>
                    <div class="inline ms-2 text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                        @if (transaction.operation_type == 'reversal') {
                            {{ transaction.channel }}
                        } @else {
                            -
                        }
                    </div>
                }
                
            </div>
        </div>
    </app-card-info>
}

@if (channel == 'IN_PERSON') {
    <div class="flex gap-x-5">
        <!-- box Detalle de transacción -->
        <app-card-info class="basis-[56%]" cardTitle="Detalle de transacción" titleClass="ps-6" contentClass="items-stretch">
            <div class="flex-1 text-start px-6 basis-[20%]">
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        @if (operation_type == 'purchase') {
                            <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                                MONTO TOTAL:
                            </span>
                            <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                                {{ normalizeDataService.currencyFormat(transaction.amount) }}
                            </div>
                        }
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            @if (transaction.operation_type == 'reversal') {
                                MONTO DE REVERSA
                            } @else {
                                MONTO DE DEVOLUCIÓN
                            }
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (operation_type != 'purchase') {
                                {{ normalizeDataService.currencyFormat(transaction.amount) }}
                            } @else {
                                @if (transaction.refunded_amount) {
                                    {{ normalizeDataService.currencyFormat(transaction.refunded_amount) }}
                                } @else {
                                    {{ normalizeDataService.currencyFormat(0) }}
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            COMISIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if (normalizeDataService.totalComission(transaction).hasValue) {
                                    {{ normalizeDataService.totalComission(transaction).text }}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONTO A ABONAR:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if (normalizeDataService.depositAmount(transaction).hasValue) {
                                    {{ normalizeDataService.depositAmount(transaction).text }}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            PROPINA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            -
                        </div>
                    }
                    
                </div>
            </div>
            <div class="flex-1 text-start px-6 basis-[20%] border-e-2 border-indigo-50">
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONEDA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.currency }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            CUOTAS:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ normalizeDataService.installments(transaction) }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            ESTADO DE TRANSACCIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{normalizeDataService.status(transaction.status)}}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            FECHA DE ABONO:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if(transaction.deposit_date) {
                                    {{ transaction.deposit_date | date:'yyyy-MM-dd'}}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            CÓDIGO AUTORIZACIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.authorization_code }}
                        </div>
                    }
                    
                </div>
            </div>
            <div class="flex-1 text-start px-6 basis-[10%]">
                @if (loader) {
                    <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '75px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" 
                        [theme]="{ width: '100%', height: '90px', margin: '0px', 'border-radius': '8px' , cursor: 'auto'}">
                    </ngx-skeleton-loader>
                } @else {
                    <span class="text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px]">
                        GEOLOCALIZACIÓN
                    </span>
                    <div>
                        @if(transaction.geolocation == "") {
                            <app-google-map latitude="-33.4446479" longitude="-70.6586581"/>
                        } @else {
                            <app-google-map latitude="{{transaction.geolocation.split(',')[0].trim()}}" longitude="{{transaction.geolocation.split(',')[1].trim()}}"/>   
                        }
                    </div>
                }
            </div>
        </app-card-info>
        <!-- box Método de pago -->
        <app-card-info class="basis-[44%]" h-full cardTitle="Método de pagos" titleClass="ps-6" contentClass="items-stretch">
            <div class="flex-1 text-start px-6 basis-[60%]">
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            MARCA:
                        </span>
                        @if(transaction.network == 'VISA'){
                            <div class="inline-block w-[25px] rounded-sm items-center" style="vertical-align: bottom;">
                                <img src="../../../assets/img/sales/ic-visa.svg" />
                            </div>
                        } @else {
                            <div class="inline-block w-[33px] rounded-sm items-center" style="vertical-align: bottom;">
                                <img src="../../../assets/img/sales/ic-mastercard.svg" />
                            </div>
                        }
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '240px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            NÚMERO DE TARJETA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.masked_card_number }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '155px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            TIPO DE TARJETA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ normalizeDataService.creditCardType(transaction.type) }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '155px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            PAÍS DE LA TRANSACCIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            Chile
                        </div>
                    }
                    
                </div>
                <!-- <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            INFORMACIÓN 3D SECURE:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            N° D84322100Ab
                        </div>
                    }
                    
                </div> -->
            </div>
            <div class="flex-1 text-start px-6 basis-[40%]" contentClass="items-stretch">
                <!-- <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS ECI:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_eci }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS SPECIFICATION VERSION:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_specificationVersion }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS ACCEPT RISK:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_acceptRisk }}
                        </div>
                    }
                    
                </div> -->
            </div>
        </app-card-info>
    </div>
} @else {
    <div class="flex gap-x-5">
        <!-- box Detalle de transacción -->
        <app-card-info class="basis-[50%]" cardTitle="Detalle de transacción" titleClass="ps-6" contentClass="items-stretch">
            <div class="flex-1 text-start px-6 basis-[55%]">
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONTO TOTAL:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ normalizeDataService.currencyFormat(transaction.amount) }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONTO DE DEVOLUCIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.refunded_amount) {
                                {{ normalizeDataService.currencyFormat(transaction.refunded_amount) }}
                            } @else {
                                {{ normalizeDataService.currencyFormat(0) }}
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            COMISIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if (normalizeDataService.totalComission(transaction).hasValue) {
                                    {{ normalizeDataService.totalComission(transaction).text }}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONTO A ABONAR:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if (normalizeDataService.depositAmount(transaction).hasValue) {
                                    {{ normalizeDataService.depositAmount(transaction).text }}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            PROPINA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            -
                        </div>
                    }
                    
                </div>
            </div>
            <div class="flex-1 text-start px-6 basis-[45%]">
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            MONEDA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.currency }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            CUOTAS:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ normalizeDataService.installments(transaction) }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            ESTADO DE TRANSACCIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{normalizeDataService.status(transaction.status)}}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            FECHA DE ABONO:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            @if (transaction.status == 'error' || transaction.status == 'processing' || transaction.operation_type == 'reversal') {
                                -
                            } @else {
                                @if(transaction.deposit_date) {
                                    {{ transaction.deposit_date | date:'yyyy-MM-dd'}}
                                } @else {
                                    - <app-tooltip [tooltipTitle]="tooltipTitle" />
                                }
                            }
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader ) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px', 'border-radius': '11.765px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[115px]">
                            CÓDIGO AUTORIZACIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.authorization_code }}
                        </div>
                    }
                    
                </div>
            </div>
        </app-card-info>
        <!-- box Método de pago -->
        <app-card-info class="basis-[50%]" h-full cardTitle="Método de pagos" titleClass="ps-6" contentClass="items-stretch">
            <div class="flex-1 text-start px-6 basis-[60%]">
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '140px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            MARCA:
                        </span>
                        @if(transaction.network == 'VISA'){
                            <div class="inline-block w-[25px] rounded-sm items-center" style="vertical-align: bottom;">
                                <img src="../../../assets/img/sales/ic-visa.svg" />
                            </div>
                        } @else {
                            <div class="inline-block w-[33px] rounded-sm items-center" style="vertical-align: bottom;">
                                <img src="../../../assets/img/sales/ic-mastercard.svg" />
                            </div>
                        }
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '240px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            NÚMERO DE TARJETA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            <!-- {{ transaction.bin.slice(0, 4) + ' ' + transaction.bin.slice(4, 6) + 'XX' }} XXXX XXXX -->
                            {{ transaction.bin.slice(0, 4) + ' ' + transaction.bin.slice(4, 6) + 'XX' }} XXXX {{ transaction.lastfourdigit }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '155px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            TIPO DE TARJETA:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ normalizeDataService.creditCardType(transaction.type) }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '155px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            PAÍS DE LA TRANSACCIÓN:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            Chile
                        </div>
                    }
                    
                </div>
                <!-- <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[120px]">
                            INFORMACIÓN 3D SECURE:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            N° D84322100Ab
                        </div>
                    }
                    
                </div> -->
            </div>
            <div class="flex-1 text-start px-6 basis-[40%]" contentClass="items-stretch">
                <!-- <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS ECI:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_eci }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS SPECIFICATION VERSION:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_specificationVersion }}
                        </div>
                    }
                    
                </div>
                <div>
                    @if (loader) {
                        <ngx-skeleton-loader count="1" 
                            [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                        </ngx-skeleton-loader>
                    } @else {
                        <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                            3DS ACCEPT RISK:
                        </span>
                        <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                            {{ transaction.threeds_acceptRisk }}
                        </div>
                    }
                    
                </div> -->
            </div>
        </app-card-info>
    </div>
}

<!-- box Información adicional de transacción-->
<app-card-toggle cardTitle="Información adicional de la transacción" titleClass="ps-6" contentClass="items-stretch" iconCard="../../../../assets/img/ic-vector.svg">
    <div class="flex-1 text-start px-6 basis-[30%]">
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[110px]">
                    ORDER NUMBER:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.order_number }}
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[110px]">
                    TERMINAL:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    @if (transaction.terminalcheckout_id) { 
                        {{ transaction.serial_number }}
                    } @else {
                        -
                    }
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[110px]">
                    SOURCE:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.source }}
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[110px]">
                    DESCRIPCIÓN:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.description }}
                </div>
            }
            
        </div>
    </div>
    <div class="flex-1 text-start px-6 basis-[30%]">
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    ACEPTA CUOTAS:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.installment_enabled ? 'SÍ': 'NO' }}
                </div>
            }
            
        </div>
        <!-- <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '170px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    ACEPTACIÓN PAGOS PARCIALES:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    SI
                </div>
            }
            
        </div> -->
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '260px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    CREATED AT:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.created_at_time_zone | date:'yyyy-MM-dd' + ' ' + 'HH:mm:ss' }}
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '260px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    UPDATE AT:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.updated_at_time_zone | date:'yyyy-MM-dd' + ' ' + 'HH:mm:ss' }}
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '260px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    EXPIRED AT:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    -
                </div>
            }
            
        </div>
    </div>
    <div class="flex-1 text-start px-6 basis-[40%]">
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '340px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    ID TERMINAL/ID ONLINE:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    @if (transaction.terminalcheckout_id) { 
                        {{ transaction.terminalcheckout_id }}
                    } @else {
                        {{ transaction.onlinecheckout_id }}
                    }
                </div>
            }
            
        </div>
        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    MODE:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    {{ transaction.mode }}
                </div>
            }
            
        </div>

        <div>
            @if (loader) {
                <ngx-skeleton-loader count="1" 
                    [theme]="{ width: '200px', height: '10px', margin: '0px' , cursor: 'auto'}">
                </ngx-skeleton-loader>
            } @else {
                <span class="inline-block text-slate-400 text-[8px] font-medium font-['Inter'] leading-[15px] min-w-[150px]">
                    METADATA:
                </span>
                <div class="inline text-slate-800 text-[10px] font-semibold font-['Inter'] leading-[13px]">
                    -
                </div>
            }
            
        </div>
    </div>
</app-card-toggle>

<!-- ... (código existente) ... -->

<section class="botoncito mt-auto items-center flex">
    <a routerLink="/transacciones" class="mx-auto w-60 px-[25px] py-[10px] bg-indigo-300 rounded-[14px] text-center items-center align-middle">
        <span class="text-white font-semibold font-['Inter']">
            Volver
        </span>
    </a>
</section>