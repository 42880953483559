import { Component, Output, EventEmitter } from '@angular/core';
import {FormGroup, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JsonPipe} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    JsonPipe,
    DatePipe,
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent {
  @Output() rangeEvent = new EventEmitter<string>();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  startValue: Date | null = null;
  endValue: Date | null = null;

  constructor(
    private datePipe: DatePipe,
    ) {
    // Suscribirse a los cambios en los controles de formulario
    this.range.get('start')?.valueChanges.subscribe(value => {
      this.startValue = value;
      console.log(this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss\'Z\''));
      localStorage.setItem('calendar.created_at_from', this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss\'Z\''));
    });

    this.range.get('end')?.valueChanges.subscribe(value => {
      this.endValue = value;
      
      if (value) {
        value.setHours(23, 59, 59);
        console.log(this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss\'Z\''));
        localStorage.setItem('calendar.created_at_to', this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss\'Z\''));

        this.rangeEvent.emit(new Date().getTime().toString());
      }
      
    });
  }

}
