import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {} from '../services/portal.service'
import { environment } from '../../environments/environment';

const { loginAzureUrl } = environment;

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private route: ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('auth.access_token');

    if (this.loggedIn() && token) {

      return true;
    } else {
      window.location.href = loginAzureUrl;
      return false;
    }
  }

  private loggedIn(): boolean {
    const token = localStorage.getItem('auth.access_token');
    const expiresOn = localStorage.getItem('auth.expires_on');
    const isExpired =
      token && expiresOn
      ? parseFloat(expiresOn) < Date.now() / 1000
      : false;

    return !isExpired;
  }
}
