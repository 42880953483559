import { Component, Input, ContentChild, QueryList, ElementRef, TemplateRef} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { DatePipe } from '@angular/common';
import localeEsCl from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

@Component({
  selector: 'app-card-info-credit',
  standalone: true,
  imports: [ 
    NgTemplateOutlet,
    DatePipe
  ],
  templateUrl: './card-info-credit.component.html',
  styleUrl: './card-info-credit.component.css'
})
export class CardInfoCreditComponent {
  actualDate: string;
  previousDate: string;

  constructor(private datePipe: DatePipe) {
    registerLocaleData(localeEs); // Registra el idioma español

    const currentDate = new Date();
    this.actualDate = this.datePipe.transform(currentDate, 'MMMM y');

    const previousMonthDate = new Date(currentDate);
    previousMonthDate.setMonth(currentDate.getMonth() - 1);

    this.previousDate = this.datePipe.transform(previousMonthDate, 'MMMM y');
  }

  @ContentChild('titleTemplate')
  titleTemplate?: TemplateRef<any> = undefined;

  @Input()
  title: string = 'title';

  @Input()
  titleClass: string = 'text-start';

  @Input()
  contentClass: string = '';

  @Input()
  containerClass: string = '';

  @Input()
  mesActual: string = '';
  
  @Input()
  mesAnterior: string = '';

  @Input()
  proximoAbono: string = '';

  @Input()
  hasTitle: boolean = true;

  ngOnInit() {
    console.log('ngOnInit!');
  }
}
