import { Routes } from '@angular/router';
import { LoginComponent } from './users/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { AuthGuardService } from './services/auth.guard.service';
import { SalesComponent } from './sales/sales/sales.component';
import { SaleDetailComponent } from './sales/sale-detail/sale-detail.component';
import { CreditsComponent } from './credit/credits/credits.component';
import { CreditDetailComponent } from './credit/credit-detail/credit-detail.component';
import { AuthComponent } from './auth/auth.component';

export const routes: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full'
            },
            {
                path: 'inicio',
                component: DashboardComponent,
            },
            {
                path: 'transacciones',
                component: SalesComponent,
            },
            {
                path: 'transacciones/:id',
                component: SaleDetailComponent,
            },
            {
                path: 'abonos',
                component: CreditsComponent,
            },
            {
                path: 'abonos/:depositDate/:channel',
                component: CreditDetailComponent,
            },
            {
                path: 'informes',
                component: CreditsComponent,
            },
            {
                path: 'clientes',
                component: CreditsComponent,
            },
            {
                path: 'configuración',
                component: CreditsComponent,
            },
        ]
    }
];
