<div class="refund-bg"></div>
<div class="refund-container">
    <app-card-status>
        <div class="contenido border-indigo-50">
            <div class="parrafo text-opacity-50 text-[14px] font-normal font-['Inter'] leading-tight mb-6 text-center">
                <img src="../../../../assets/img/ic-fail.svg" class="mx-auto" />
            </div>            
            <div class="parrafo-1 text-opacity-50 text-[14px] font-normal font-['Inter'] leading-tight mb-2 text-cebter">
                No pudimos realizar tu reembolso
            </div>
            <div class="parrafo-2 text-opacity-50 text-[14px] font-normal font-['Inter'] leading-tight mb-11 text-center">
                Lamentamos informarte que no pudimos completar la solicitud de devolución en este momento. Por favor intenta la devolución nuevamente.
            </div>
            <div class="flex flex-col mx-auto">
                <button (click)="makeRefund()" class="w-[303px] h-[38.74px] bg-indigo-500 rounded-xl items-center mb-4 mx-auto">
                    <div class="margen text-white text-xs font-bold font-['Poppins']">Volver a intentar devolución</div>
                </button>
                <button (click)="handleRefundComponent()" class="w-[303px] h-[38.74px] bg-white rounded-xl border border-zinc-400 mx-auto">
                    <div class="text-zinc-400 text-xs font-bold font-['Poppins']">Cancelar</div>
                </button>
            </div>
        </div>
    </app-card-status>
</div>