import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from './token.interceptor';
import { APOLLO_OPTIONS, ApolloModule, Apollo} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {VERSION as MAT_VERSION, MatNativeDateModule, DateAdapter, NativeDateAdapter  } from '@angular/material/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SalesComponent } from './sales/sales/sales.component'
import { CreditsComponent } from './credit/credits/credits.component'
import { PaymentIntentsService } from './services/graphQl/payment-intents/payment-intents.service'
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { CustomDateAdapter } from './custom-date-adapter';

import localeEsCl from '@angular/common/locales/es-CL';

const { graphqlUrl } = environment;

registerLocaleData(localeEsCl, 'es-CL');

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe,
    Apollo,
    SalesComponent,
    CreditsComponent,
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(MatNativeDateModule),
    provideHttpClient(),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    {
      provide: LOCALE_ID, 
      useValue: 'es-CL'
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: graphqlUrl,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    }
  ]
};
