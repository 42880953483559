import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-google-map',
  standalone: true,
  imports: [],
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.css'
})
export class GoogleMapComponent {

  private apiKey = 'AIzaSyBSWbS24VMbPfLbEuixppKg7ZpRGxuLEw0';

  @Input()
  public latitude: string = '0.0';

  @Input()
  public longitude: string = '0.0';

  private zoom = 15;

  public url: any = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.url = this.getMapUrl();
  }

  getMapUrl(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.latitude},${this.longitude}&zoom=${this.zoom}`
    );
  }
}
