import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  refundButton: boolean = false;
  refundSuccess: boolean = false;
  refundFail: boolean = false;
  public transactions: any[] = [];
  public depositDetail: any[] = [];
  public credits: any[] = [];
  public amount: number;
  public bin: string;
  public lastfourdigit: string;
  public network: string;
  public channel: string;
  private windowId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  public merchantName: string;
  private merchantNameSubject = new BehaviorSubject<string>('');
  
  constructor() {
    this.credits = [];
    this.transactions = [];
  }

  setRefundButton(value: boolean): void {
    this.refundButton = value;
  }

  setRefundFail(value: boolean): void {
    this.refundFail = value;
  }

  setRefundSuccess(value: boolean): void {
    this.refundSuccess = value;
  }

  setChannel(channel: string): void {
    localStorage.setItem(`channel_${this.windowId}`, channel);
  }

  getChannel(): string | null {
    return localStorage.getItem(`channel_${this.windowId}`);
  }

  setMerchantName(merchantName: string): void {
    this.merchantNameSubject.next(merchantName);
  }

  getMerchantName$() {
    return this.merchantNameSubject.asObservable();
  }

}
