import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badges',
  standalone: true,
  imports: [],
  templateUrl: './badges.component.html',
  styleUrl: './badges.component.css'
})
export class BadgesComponent {

  @Input()
  text!: string;

  @Input()
  bgColor: string = 'bg-green-400';

  @Input()
  textColor: string = 'text-white';

}
