const tokenRedirectUri = 'https://portal.dev.baaskit.com/auth';
const loginAzureBaseUrl = 'https://baaskitclientes.b2clogin.com/idp.baaskit.com/oauth2/v2.0/authorize';
const clientId = '49b25467-662f-4891-8785-9eff2c81d8f1';

let loginAzureParams = new URLSearchParams([
    ['p', 'B2C_1_portal_comercio'],
    ['client_id', clientId],
    ['nonce', 'defaultNonce'],
    ['redirect_uri', tokenRedirectUri],
    ['scope', 'openid'],
    ['response_type', 'code'],
    ['prompt', 'login'],
]);

export const environment = {
    production: false,
    local: false,
    baseUrl: 'https://api.dev.baaskit.com/v1/cl',
    graphqlUrl: 'https://graphql.dev.baaskit.com/graphql',
    tokenUrl: 'https://api.dev.baaskit.com/v1/cl/oauth2/token',
    tokenRedirectUri,
    loginAzureUrl: `${loginAzureBaseUrl}?${loginAzureParams.toString()}`,
    clientId,
    quickSightBaseUrl: 'https://api.dev.baaskit.com/v1/cl/dashboard',
    quickSightDashboards: {
        credits: '328fb255-a2bc-4fc2-8af5-9f5e06b76adc',
        dashboard: 'e72d1684-28ff-11ef-bdc6-00155d0f0547',
        sales: '898888c7-a575-4848-9740-3f0223d6aa62'
    }
};
