import { Injectable } from '@angular/core';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentIntentsService {

  constructor(private apollo: Apollo,) { }

  public queryPaymentIntent(): Observable<any> {

    const authToken = localStorage.getItem('auth.access_token');

    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);

    return this.apollo
      .query({
        query: gql`
          query PaymentIntents($merchantId: String!, $createdAtFrom: String!, $createdAtTo: String!, $timeZone: String!) {
            paymentIntents(
              merchant_id: $merchantId
              created_at_from: $createdAtFrom
              created_at_to: $createdAtTo
              time_zone: $timeZone
            ) {
              items {
                id
                amount
                type
                bin
                lastfourdigit
                network
                status
                operation_type
                operation_number
                updated_at
                channel
                created_at_time_zone
                updated_at_time_zone
              }
            }
          }
        `,
        variables: {
          merchantId: localStorage.getItem('auth.merchant_id'),
          createdAtFrom: localStorage.getItem('calendar.created_at_from').replace('Z', ''),
          createdAtTo: localStorage.getItem('calendar.created_at_to').replace('Z', ''),
          timeZone: localStorage.getItem('auth.time_zone'),
        },
        context: {
          headers: headers,
        },
        fetchPolicy: 'network-only',
      })
  }

  public queryPaymentIntentDetail(paymentId: string): Observable<any> {

    const authToken = localStorage.getItem('auth.access_token');

    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);

    return this.apollo
      .query({
        query: gql`
          query PaymentIntents($transactionId: String!, $timeZone: String!) {
            paymentIntentDetail(
              id: $transactionId
              time_zone: $timeZone
            ){
              amount
              authorization_code
              network
              bin
              lastfourdigit
              operation_type
              created_at
              created_at_time_zone
              expired_at
              updated_at
              updated_at_time_zone
              mode
              order_number
              currency
              status
              type
              description
              source
              id
              threeds_specificationVersion
              threeds_eci
              threeds_acceptRisk
              channel
              operation_number
              deposit_date
              total_commissions
              final_amount
              terminal_type
              terminalcheckout_id
              onlinecheckout_id
              geolocation
              refunded_amount
              installment_count
              installment_enabled
              total_taxes
              serial_number
              deposit_amount
            }
          }
        `,
        variables: {
          transactionId: paymentId,
          timeZone: localStorage.getItem('auth.time_zone'),
        },
        context: {
          headers: headers,
        },
        fetchPolicy: 'network-only',
      })
  }
}
