import { Component } from '@angular/core';
import { CardStatusComponent } from '../card-status/card-status.component';
import { RouterLink, ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../../../services/shared-data.service';
import { PortalService } from '../../../services/portal.service';

@Component({
  selector: 'app-refund-fail',
  standalone: true,
  imports: [
    CardStatusComponent,
    RouterLink,
  ],
  templateUrl: './refund-fail.component.html',
  styleUrl: './refund-fail.component.css'
})
export class RefundFailComponent {

  constructor(
    private sharedDataService: SharedDataService,
    private portalService: PortalService,
  ) {}

  handleRefundComponent(): void {
    this.sharedDataService.setRefundFail(false);
    localStorage.removeItem('refund.bin');
    localStorage.removeItem('refund.lastfourdigit');
    localStorage.removeItem('refund.network');
    localStorage.removeItem('refund.payment_id');
  }

  public makeRefund(): void {
    this.sharedDataService.setRefundButton(true);
    this.sharedDataService.setRefundFail(false);
  }

}
