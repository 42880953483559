import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NormalizeDataCreditService {

  constructor() { }

  private currencyConfig = {
    code: 'es-CL',
    prefix: ' CLP',
    options: { 
      style: 'currency', 
      currency: 'CLP'
    }
  };

  public currencyFormat(amount: any): string {
    return `${amount.toLocaleString(this.currencyConfig.code, this.currencyConfig.options)}${this.currencyConfig.prefix}`
  }

  public totalComission(credit: any): any {
    if(credit.total_tax == null && credit.total_commission == null) {
      return { 
        hasValue: false,
        text: '-'
      }
    } else {
      const taxes = credit.total_tax ?? 0;
      const comission = credit.total_commission ?? 0;
      const total = taxes + comission;

      return { 
        hasValue: true,
        text: this.currencyFormat(total)
      }
    }
  }
}
