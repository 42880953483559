<div class="refund-bg"></div>
<div class="refund-container">
    <app-card-status>
        <div class="contenido border-indigo-50">
            <div class="parrafo text-opacity-50 text-[14px] font-normal font-['Inter'] leading-tight mb-6 text-center">
                <img src="../../../../assets/img/ic-check.svg" class="mx-auto" />
            </div>            
            <div class="parrafo mb-2 text-center text-neutral-600 text-lg font-semibold font-['Inter'] leading-7">
                Devolución Exitosa
            </div>
            <div class="parrafo mb-7 mx-4 text-center text-black text-opacity-50 text-sm font-normal font-['Inter'] leading-tight">
                La devolución se ha realizado con éxito. A continuación, encontrarás los detalles de la devolución realizada.
            </div>
            <div class="cuadro mb-8 p-3">
                <div class="flex column mb-3">
                    <span class="text-neutral-600 text-sm font-medium font-['Inter'] leading-snug">Detalle de devolución</span>
                    <img src="../../../../assets/img/ic-chevron-down.svg" style="margin-left: auto;"/>
                </div>
                <div class="flex column">
                    <span class="text-neutral-600 text-xs font-normal font-['Inter'] leading-[18.37px]" >Monto total:</span>
                    <span class="text-neutral-600 text-xs font-semibold font-['Inter'] leading-[18.37px] ml-2" >{{amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}} CLP</span>
                    <div class="inline-block rounded-sm items-center" style="display: flex; align-items: center; margin-left: auto;">
                        @if(network == 'MASTERCARD'){
                            <img class="w-[17px]" src="../../../assets/img/sales/ic-mastercard.svg" />
                        } @else {
                            <img class="w-[25px]" src="../../../assets/img/sales/ic-visa.svg" />
                        }
                        <span class="text-neutral-600 text-[10.85px] font-semibold font-['Inter'] leading-3 pl-2" style="display: flex; align-items: center;">{{ bin.slice(0, 4) + ' ' + bin.slice(4, 6) + 'XX' }} XXXX {{ lastfourdigit }}</span>
                    </div>
                </div>
            </div>
            <section class="items-center flex">
                <a (click)="handleRefundComponent()" routerLink="/transacciones" class="mx-auto px-[25px] py-[10px] rounded-[6.2px] text-center items-center align-middle" style="display: block; width: 100%; height: 100%; background: #737CFD;">
                    <span class="text-white font-semibold font-['Inter']">
                        Volver a transacciones
                    </span>
                </a>
            </section>
        </div>
    </app-card-status>
</div>