import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, Router, ActivatedRoute } from '@angular/router';
import { PortalService } from './services/portal.service';
import { AuthGuardService } from './services/auth.guard.service';
import { SharedDataService } from './services/shared-data.service';
import { JwtDecoderService } from './services/jwt-decoder.service';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { MerchantService } from './services/merchant.service'


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    HttpClientModule, 
    ApolloModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'portal-comercio-test';
  queryParamCode: string;
  transactions: any[] = [];
  merchantId: string;
  authToken: string;
  fechaHora = new Date();
  opciones: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };
  formatoFechaHora = new Intl.DateTimeFormat('es-ES', this.opciones).format(this.fechaHora);

  constructor(
    private route: ActivatedRoute,
    private portalService: PortalService,
    private authGuardService: AuthGuardService,
    private jwtDecoderService: JwtDecoderService,
    private apollo: Apollo,
    private sharedDataService: SharedDataService,
    private merchantService: MerchantService,
    private router: Router
    ) { 
    this.queryParamCode = '';
  }

  public ngOnInit() {
    this.initQueryParamCode();
  }

  initQueryParamCode(): void {
    this.route.queryParams.subscribe((params) => {
      // Aquí puedes acceder a los parámetros de la URL
      console.log(params);

      if(params == null || Object.keys(params).length == 0) {
        return;
      }
      
      // Azure B2C Code
      const code = params['code'];
      console.log(code);
      this.queryParamCode = code;
      localStorage.setItem('intention', JSON.stringify(code));

      this.portalService.loginAuth(this.queryParamCode).subscribe(
        (login) => {
          console.log(login);
          console.log("Code expires:", login.id_token_expires_in);
          const token  = login.id_token;
          const expiresCode = login.id_token_expires_in;
          const expiresOn  = login.not_before.toString();
          const decodedToken = this.jwtDecoderService.decodeToken(token);
          localStorage.setItem('code.access_token', expiresCode.toString());
          localStorage.setItem('auth.access_token', token);
          localStorage.setItem('auth.expires_on', decodedToken.exp);
          localStorage.setItem('auth.merchant_id', decodedToken.extension_BaaskitPagosMerchantID);
          localStorage.setItem('auth.time_zone', decodedToken.extension_Timezone);
          localStorage.setItem('auth.login', this.formatoFechaHora);
          console.log(decodedToken);
          console.log("merchant_id",decodedToken.extension_BaaskitPagosMerchantID);
          const newURL = window.location.href.split('?')[0];
          history.replaceState({}, document.title, newURL);
  
          const today = new Date();
          const formattedStartDate = today.toISOString().split('T')[0] + 'T00:00:00Z';
          const formattedEndDate = today.toISOString().split('T')[0] + 'T23:59:59Z';
  
          localStorage.setItem('calendar.created_at_from', formattedStartDate);
          localStorage.setItem('calendar.created_at_to', formattedEndDate);

          this.authToken = localStorage.getItem('auth.access_token');
          this.merchantId = localStorage.getItem('auth.merchant_id');

          // Se obtienen datos de API Merchant
          this.merchantService.MerchantStateMachine(this.authToken, this.merchantId).subscribe(
            (merchant) => {
              console.log("Merchant:",merchant);
              this.sharedDataService.setMerchantName(merchant.merchant_name);
              localStorage.setItem('merchant_logo', merchant.branding.logo);
              this.router.navigate(['/inicio']);
            }
          );

        }
      );

    });
  }
}
