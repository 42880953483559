import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ApiMerchant } from '../models/merchant/merchant.interface';
import { environment } from '../../environments/environment'

const { baseUrl } = environment;

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(private httpClient: HttpClient) { }

  MerchantStateMachine(authToken: string, merchantId: string): Observable<ApiMerchant> {
    const url = `${baseUrl}/merchant/${merchantId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      })
    };

    const response = this.httpClient.get<ApiMerchant>(
      url,
      httpOptions
    );
  
    console.log(response);
    return response;
  }
  
}
