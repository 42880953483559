import { Component } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { BadgesComponent } from '../../layout/components/badges/badges.component';
import { CardInfoComponent } from '../../layout/components/card-info/card-info.component';
import { CardInfoCreditComponent } from '../../layout/components/card-info-credit/card-info-credit.component';
import { CardInfoGraphicComponent } from '../../layout/components/card-info-graphic/card-info-graphic.component';
import { RouterLink } from '@angular/router';
import { SharedDataService } from '../../services/shared-data.service'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CalendarComponent } from '../../layout/calendar/calendar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http'
import { DatePipe } from '@angular/common';
import { DepositsService } from '../../services/graphQl/deposits/deposits.service';
import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';
import { take } from 'rxjs';
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { NormalizeDataCreditService } from '../../services/normalize-data-credit.service';

const { quickSightBaseUrl, quickSightDashboards } = environment;

@Component({
  selector: 'app-credits',
  standalone: true,
  imports: [
    DataViewModule,
    BadgesComponent,
    RouterLink,
    CardInfoComponent,
    ScrollingModule,
    CalendarComponent,
    NgxSkeletonLoaderModule,
    ApolloModule,
    HttpClientModule,
    CardInfoCreditComponent,
    CardInfoGraphicComponent,
    DatePipe
  ],
  templateUrl: './credits.component.html',
  styleUrl: './credits.component.css'
})
export class CreditsComponent {
  credits: any[] = [];
  creditsLoading: any[] = [{},{},{},{},{},];
  loader: boolean = true;
  createdAtFrom: string = localStorage.getItem('calendar.created_at_from');
  createdAtTo: string = localStorage.getItem('calendar.created_at_to');
  formattedCreatedAtFrom: string = '';
  formattedcreatedAtTo: string = '';
  dashboard: QuicksightEmbedding.DashboardExperience;
  quickSightUrl: string;
  embeddingContext:EmbeddingContext;

  constructor(
    public sharedDataService: SharedDataService,
    private apollo: Apollo,
    private datePipe: DatePipe,
    private depositsService: DepositsService,
    private http: HttpClient,
    public normalizeDataCreditService: NormalizeDataCreditService
  ) {
    this.formattedCreatedAtFrom = this.datePipe.transform(localStorage.getItem('calendar.created_at_from'), 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(localStorage.getItem('calendar.created_at_to'), 'dd-MM-yyyy');
  }

  ngOnInit(): void {
    this.updateDate();
    this.GetDashboardURL();
    this.makeQueryDeposits();
  }

  refreshTransactions() {
    this.loader = true;
    this.updateDate();
    this.GetDashboardURL();
    this.makeQueryDeposits();
  }

  makeQueryDeposits() {
    this.depositsService.queryDeposits().subscribe((result: any) => {
      this.credits = result.data?.deposits?.items;
      console.log("Abonos:", this.credits)
      this.sharedDataService.credits = result?.data?.deposits?.items;
      this.sortTransactionsByDate();

      this.loader = false;
    });
  }

  updateDate() {
    const createdAtFrom = new Date(localStorage.getItem('calendar.created_at_from'));
    const createdAtTo = new Date(localStorage.getItem('calendar.created_at_to'));

    // Suma un día a las fechas
    createdAtFrom.setDate(createdAtFrom.getDate() + 1);
    // createdAtTo.setDate(createdAtTo.getDate() + 1);

    // Formatear las fechas en el formato ('dd-MM-yyyy')
    this.formattedCreatedAtFrom = this.datePipe.transform(createdAtFrom, 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(createdAtTo, 'dd-MM-yyyy');

  }

  sortTransactionsByDate() {
    if(this.credits) {
      this.credits = [...this.credits].sort((a, b) => {
        const dateA = new Date(a.updated_at).getTime();
        const dateB = new Date(b.updated_at).getTime();
        return dateB - dateA;
      });
    }
  }

  public GetDashboardURL() {
    // this.quickSightUrl = localStorage.getItem('quickSightUrl');

    const createdAtFrom = this.formattedCreatedAtFrom.substring(6,10) + "-" +  this.formattedCreatedAtFrom.substring(3,5) + "-" + this.formattedCreatedAtFrom.substring(0,2);
    const createdAtTo = this.formattedcreatedAtTo.substring(6,10) + "-" + this.formattedcreatedAtTo.substring(3,5) + "-" + this.formattedcreatedAtTo.substring(0,2);
    const merchantId = localStorage.getItem('auth.merchant_id');
    const paramsUrl = "#p.merchantId=" + merchantId + "&p.createdAtFrom=" + createdAtFrom + "&p.createdAtTo=" + createdAtTo;
    let urlWithParams = "";
    console.log("createdAtFrom", createdAtFrom);
    console.log("createdAtTo", createdAtTo);

    if ( this.quickSightUrl == null){

      const authToken = localStorage.getItem('auth.access_token');
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        })
      };

      const dashboardId = quickSightDashboards.credits;
      this.http.get(`${quickSightBaseUrl}/${dashboardId}`, httpOptions)
        .pipe(
          take(1),
        )
        .subscribe((data: any) => {
            this.quickSightUrl = data.EmbedUrl;
            //localStorage.setItem('quickSightUrl',this.quickSightUrl);

            console.log("Quicksight:", data.EmbedUrl);
            urlWithParams =  data.EmbedUrl + paramsUrl;
            console.log("A-urlWithParams:", urlWithParams);
            this.Dashboard(this.quickSightUrl,merchantId,createdAtFrom,createdAtTo);
        });
    }else{
        this.Dashboard(this.quickSightUrl,merchantId,createdAtFrom,createdAtTo);
    }
  }

  public async Dashboard(embeddedURL: any,merchantId:string,createdAtFrom:string,createdAtTo:string) {
    var containerDiv = document.getElementById("dashboardContainer") || '';
    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "345px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: false,
    }
    this.embeddingContext = await createEmbeddingContext();
    if ( this.dashboard == null){
      this.dashboard = await this.embeddingContext.embedDashboard(frameOptions);
      console.log("",this.dashboard);
    }else{
      this.dashboard.setParameters([
        {
          Name: 'merchantId',
          Values: [merchantId]
        },
        {
          Name: 'createdAtFrom',
          Values: [createdAtFrom]
        },
        {
          Name: 'createdAtTo',
          Values: [createdAtTo]
        }
      ]);
    }
  }
}
