import { Component } from '@angular/core';
import { CardStatusComponent } from '../card-status/card-status.component';
import { RouterLink, ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../../../services/shared-data.service';

@Component({
  selector: 'app-refund-success',
  standalone: true,
  imports: [
    CardStatusComponent,
    RouterLink,
  ],
  templateUrl: './refund-success.component.html',
  styleUrl: './refund-success.component.css'
})
export class RefundSuccessComponent {
  bin: any = localStorage.getItem('refund.bin');
  lastfourdigit: any = localStorage.getItem('refund.lastfourdigit');
  network: any = localStorage.getItem('refund.network');
  amount: any = this.sharedDataService.amount;

  constructor(
    private sharedDataService: SharedDataService,
  ) {}

  handleRefundComponent(): void {
    this.sharedDataService.setRefundSuccess(false);
    localStorage.removeItem('refund.bin');
    localStorage.removeItem('refund.lastfourdigit');
    localStorage.removeItem('refund.network');
  }
}
