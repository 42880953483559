<section [class]="'rounded-[10.394px] flex flex-col shadow mb-3 ' + containerClass" style="background: linear-gradient(to right, rgb(248, 248, 255), #FFF);">
    @if (hasTitle) {
        <!-- titulo box resumen -->
        <div [class]="'rounded-[10.394px] py-2 pt-3  ' + titleClass" >
            <ng-container *ngTemplateOutlet="titleTemplate || defaultDescriptionTemplate"></ng-container>
        </div>
    }
    <!-- contenido box resumen -->
    <div [class]="'flex-1 flex text-center items-center pb-3 ' + contentClass">
        <ng-content></ng-content>
    </div>
</section>

<ng-template #defaultDescriptionTemplate>
    <span class="text-neutral-600 text-sm font-bold font-['Inter'] leading-loose relative flex pr-6">
        {{ title }}
    </span>
</ng-template>