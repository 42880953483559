<section class="flex mb-5">
    <span class="flex-1">
        <span class="text-neutral-600 text-lg font-normal font-['Inter'] leading-normal">¡Bienvenido a tu portal Baaskit,</span>
        <span class="text-neutral-600 text-lg font-bold font-['Inter'] leading-normal"> <br/>{{merchantName}}</span>
        <span class="text-neutral-600 text-lg font-normal font-['Inter'] leading-normal"> te dejamos un breve resumen de tu negocio!</span>
        <img class="inline-block ms-2" src="../../../assets/img/ic-rocket.svg" />
    </span>
    <div class="flex gap-2">
    </div>
</section>

<div class="flex-1 dashboard-container" id="dashboardContainer"></div>

<!-- <div class="flex">
    <app-card-summary title="Próximo Abono" titleClass="ps-6" style="width: 519px;">
        <div class="flex mb-3">
            <div class="amount-info flex-1 flex text-center items-center text-neutral-600 text-2xl font-bold font-['Inter']">
                $ 6.000.000 CLP
            </div>
            <button>
                <img class="ml-10 flex items-center" src="../../../assets/img/ic-summary.svg" />
            </button>
        </div>
    </app-card-summary>
</div>
<div class="flex">
    <app-card-summary title="Resumen de Ventas" titleClass="ps-6" class="mr-3" style="width: 519px;">
        <div class="flex mb-3">
            <div class=" flex-1 flex items-center text-neutral-600 text-xs font-bold font-['Inter']">
                <div class="sales-info">
                    Ventas generadas
                </div>
            </div>
            <div class="amount-info flex-1 flex items-center text-neutral-600 text-xl font-bold font-['Inter']">
                $ 7.100.000 CLP
            </div>
        </div>
        <img class="mb-5" src="../../../assets/img/image-1.svg" />
        <div class="flex justify-end mb-3">
            <button class="mr-7 px-[37px] py-[8px] bg-indigo-500 rounded-xl inline-flex relative flex items-center">
                <div class="text-white font-bold font-['Poppins']" style="font-size: 10.096px;">Ver detalle</div>
            </button>
        </div>
    </app-card-summary>


    <app-card-summary title="Resumen de Abonos" titleClass="ps-6" class="ml-3" style="width: 519px;">
        <div class="flex mb-3">
            <div class=" flex-1 flex items-center text-neutral-600 text-xs font-bold font-['Inter']">
                <div class="sales-info">
                    Abonos generados
                </div>
            </div>
            <div class="amount-info flex-1 flex items-center text-neutral-600 text-xl font-bold font-['Inter']">
                $ 1.100.000 CLP
            </div>
        </div>
        <img class="mb-5" src="../../../assets/img/image-2.svg" />
        <div class="flex justify-end mb-3">
            <button class="mr-7 px-[37px] py-[8px] bg-indigo-500 rounded-xl inline-flex relative flex items-center">
                <div class="text-white font-bold font-['Poppins']" style="font-size: 10.096px;">Ver detalle</div>
            </button>
        </div>
    </app-card-summary>
</div> -->
