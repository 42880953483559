<section [class]="'bg-white rounded-[24.203px] flex flex-col shadow mb-5 py-5 px-9 '">
    <!-- contenido box resumen -->
    <div [class]="'flex-1 flex text-center items-center py-5 '">
        <ng-content></ng-content>
    </div>
</section>

<ng-template #defaultDescriptionTemplate>
    <span class="text-neutral-600 text-sm font-bold font-['Inter'] leading-loose relative flex ">
    </span>
</ng-template>