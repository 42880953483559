<section [class]="'bg-white rounded-[28.13px] flex flex-col shadow mb-5 ' + containerClass" style="box-shadow: 7.501px 15.003px 24.379px -5.626px rgba(30, 15, 62, 0.20);">
    @if (hasTitle) {
        <!-- titulo box resumen -->
        <div [class]="'bg-white rounded-[28.13px] pt-5 ml-4 ' + titleClass">
            <ng-container *ngTemplateOutlet="titleTemplate || defaultDescriptionTemplate"></ng-container>
        </div>
    }
    <!-- contenido box resumen -->
    <div [class]="'mx-10 py-5 ' + contentClass">
        <ng-content></ng-content>
    </div>
</section>

<ng-template #defaultDescriptionTemplate>
    <span class="text-neutral-600 text-sm font-bold font-['Inter'] leading-loose relative flex" style="font-size: 18.753px;">
        {{ title }}
    </span>
</ng-template>