import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiRefund, RefundRequest } from '../models/refund/refund.interface';
import { Login, LoginRequest } from '../models/LoginAuth/login.interface';
import { environment } from '../../environments/environment';

const { baseUrl, tokenUrl, tokenRedirectUri, clientId} = environment;

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  constructor(private httpClient: HttpClient) { }

  RefundCreateStateMachine(
    amount: number,
    merchant_id: string,
    paymentintent_id: string,
    authToken: string,
    idempotency_key: string
  ): Observable<ApiRefund> {

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        'idempotency_key': idempotency_key
      }),
    };

    const bodyContent: RefundRequest = {
      amount: amount,
      currency: "CLP",
      description: "Reembolso",
      reason: "solicitado por el cliente",
      location_id: "Location_id",
      paymentintent_id: paymentintent_id,
      merchant_id: merchant_id,
      terminal_serialnumber: "CP000001",
      channel: "portal"
    };

    const response = this.httpClient.post<ApiRefund>(
      `${baseUrl}/refund`,
      JSON.stringify(bodyContent),
      httpOptions
    );
    console.log(response);
    return response;
  }

  loginAuth(code: string): Observable<Login> {
    const params = new HttpParams()
    .set('type', 'portal')
    .set('code', code)
    .set('redirectUri', tokenRedirectUri)

    const response = this.httpClient.post<Login>(
      tokenUrl,
      {},
      {
        params
      }
    );
    console.log(response);
    return response;
  }

}
