import { Component } from '@angular/core';
import { SharedDataService  } from '../services/shared-data.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})
export class AuthComponent {

  merchantName: string;

  constructor(
    private sharedDataService: SharedDataService
  ) {}

  public ngOnInit() {
    this.sharedDataService.getMerchantName$().subscribe(
      (merchantName) => {
        localStorage.setItem('merchant_name', merchantName);
      }
    );
  }
}
