import { Component } from '@angular/core';
import { RouterLink, ActivatedRoute } from '@angular/router';
import { CardInfoComponent } from '../../layout/components/card-info/card-info.component';
import { GoogleMapComponent } from '../../layout/components/google-map/google-map.component';
import { BadgesComponent } from '../../layout/components/badges/badges.component';
import { DataViewModule } from 'primeng/dataview';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { SharedDataService } from '../../services/shared-data.service'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DatePipe } from '@angular/common';
import { DepositsService } from '../../services/graphQl/deposits/deposits.service';
import { NormalizeDataService } from '../../services/normalize-data.service';

@Component({
  selector: 'app-credit-detail',
  standalone: true,
  imports: [ 
    RouterLink, 
    CardInfoComponent, 
    GoogleMapComponent,
    BadgesComponent,
    DataViewModule,
    HttpClientModule,
    ApolloModule,
    NgxSkeletonLoaderModule,
    DatePipe
  ],
  templateUrl: './credit-detail.component.html',
  styleUrl: './credit-detail.component.css'
})
export class CreditDetailComponent {
  depositDetail: any[] = [];
  transactionsLoading: any[] = [{},{},{},{},{},{},{},{},{},{},{},{}];
  loader: boolean = true;
  createdAtFrom: string = localStorage.getItem('calendar.created_at_from');
  createdAtTo: string = localStorage.getItem('calendar.created_at_to');
  formattedCreatedAtFrom: string = '';
  formattedcreatedAtTo: string = '';
  depositDate: string;
  channel: string;

  constructor(
    public sharedDataService: SharedDataService, 
    private apollo: Apollo,
    private datePipe: DatePipe,
    private depositsService: DepositsService,
    private route: ActivatedRoute,
    public normalizeDataService: NormalizeDataService
  ) {
    this.formattedCreatedAtFrom = this.datePipe.transform(localStorage.getItem('calendar.created_at_from'), 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(localStorage.getItem('calendar.created_at_to'), 'dd-MM-yyyy');
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.depositDate = params.get('depositDate');
      this.channel = params.get('channel');
    });

    // Reemplaza el espacio por "T"
    this.depositDate = this.depositDate.replace(' ', 'T');

    // Agrega la "Z" al final
    this.depositDate += 'Z';

    console.log(this.depositDate)

    this.updateDate();

    this.depositsService.queryDepositDetail(this.depositDate, this.channel).subscribe((result: any) => {
      this.depositDetail = result?.data?.depositDetail[0].paymentIntents;

      this.depositDetail = this.depositDetail.map(transaction => {
        return { ...transaction, updated_at: new Date(transaction.updated_at + 'UTC') };
      });

      console.log("Transacciones:", this.depositDetail)
      this.sharedDataService.depositDetail = result?.data?.depositDetail[0].paymentIntents;
      this.sortTransactionsByDate();

      this.loader = false;
    });
  }

  updateDate() {
    const createdAtFrom = new Date(localStorage.getItem('calendar.created_at_from'));
    const createdAtTo = new Date(localStorage.getItem('calendar.created_at_to'));

    // Suma un día a las fechas
    createdAtFrom.setDate(createdAtFrom.getDate() + 1);
    // createdAtTo.setDate(createdAtTo.getDate() + 1);

    // Formatear las fechas en el formato ('dd-MM-yyyy')
    this.formattedCreatedAtFrom = this.datePipe.transform(createdAtFrom, 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(createdAtTo, 'dd-MM-yyyy');

  }

  sortTransactionsByDate() {
    this.depositDetail = [...this.depositDetail].sort((a, b) => {
      const dateA = new Date(a.updated_at).getTime();
      const dateB = new Date(b.updated_at).getTime();
      return dateB - dateA;
    });
  }

  handleClickChannel(channel: string, id: string, operation_type: string, event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    } else {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    }
  }

  handleMouseDown(channel: string, id: string, operation_type: string, event: MouseEvent): void {
    if (event.button === 1) {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    }
  }

}
