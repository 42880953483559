<mat-form-field id="date-range-field">
  <mat-label>Ingresa un rango de fecha</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date">
    <input matEndDate formControlName="end" placeholder="End date">
  </mat-date-range-input>
  <!-- <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint> -->
  <!-- <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle> -->
  <mat-date-range-picker #picker></mat-date-range-picker>

  @if (range.controls.start.hasError('matStartDateInvalid')) {
    <mat-error>Invalid start date</mat-error>
  }
  @if (range.controls.end.hasError('matEndDateInvalid')) {
    <mat-error>Invalid end date</mat-error>
  }
</mat-form-field>

<a class="boton w-[50px] h-[37px] bg-white rounded-[58px] justify-center inline-flex cursor-pointer ic-m">
  <mat-datepicker #picker class="items-center hide-calendar" style="position: relative; bottom: 4px;"></mat-datepicker>
  <mat-datepicker-toggle [for]="picker" class="hide-calendar" style="position: relative; bottom: 4px;"></mat-datepicker-toggle>
</a>

<!-- <input type="hidden" [matDatepicker]="picker"> -->

<!-- <p>Rango seleccionado: {{range.value.start | json}} {{range.value.end | json}}</p> -->