<section [class]="'bg-white rounded-[10.394px] flex flex-col shadow mb-5 ' + containerClass" >
    @if (hasTitle) {
        <!-- titulo box resumen -->
        <div [class]="'bg-white rounded-[10.394px] py-2  ' + titleClass" style="cursor: pointer; box-shadow: 0px 8.846px 16.807px 0px rgba(37, 49, 76, 0.10);" (click)="toggleContenido()">
            <ng-container *ngTemplateOutlet="titleTemplate || defaultDescriptionTemplate"></ng-container>
        </div>
    }

    @if (flag) {
        <!-- contenido box resumen -->
        <div [class]="'flex-1 flex text-center items-center py-5 ' + contentClass" >
            <ng-content></ng-content>
        </div>
    }
    
</section>

<ng-template #defaultDescriptionTemplate>
    <span class="text-neutral-600 text-sm font-bold font-['Inter'] leading-loose relative flex pr-6">
        {{ cardTitle }}
        <img class="inline ml-auto " src="{{iconCard}}" alt="" [class.rotated]="flag" [class.reverse]="!flag">
    </span>
</ng-template>
