@if (url != null) {
    <div>
        <iframe
            width="100%"
            height="90px"
            frameborder="0"
            style="border:0; border-radius: 8px;"
            referrerpolicy="no-referrer-when-downgrade"
            [src]="url"
        >
        </iframe>
    </div>
}