<section [class]="'bg-white rounded-[10.394px] flex flex-col shadow mb-3 ' + containerClass">
    @if (hasTitle) {
        <!-- titulo box resumen -->
        <div [class]="'bg-white rounded-[10.394px] py-1  ' + titleClass" style="box-shadow: 0px 8.846px 16.807px 0px rgba(37, 49, 76, 0.10);">
            <ng-container *ngTemplateOutlet="titleTemplate || defaultDescriptionTemplate"></ng-container>
        </div>
    }
    <!-- contenido box resumen -->
    <div [class]="'flex-1 flex text-center items-center pb-3 ' + contentClass">
        <ng-content></ng-content>
    </div>
</section>

<ng-template #defaultDescriptionTemplate>
    <span class="text-neutral-600 text-sm font-bold font-['Inter'] leading-loose relative flex pr-6">
        {{ title }}
        @if (!!mesAnterior) {
            <div class="inline ml-auto text-neutral-600 text-[10.10px] flex" style="align-items: center;">{{previousDate}}</div>
        } @else if (!!mesActual) {
            <div class="inline ml-auto text-neutral-600 text-[10.10px] flex" style="align-items: center;">{{actualDate}}</div>
        } @else if (!!proximoAbono) {
            <div class="inline ml-auto text-neutral-600 text-[10.10px] flex" style="align-items: center;">28 de jun 2023</div>
        }
    </span>
</ng-template>