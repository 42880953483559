import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  login: string;

  public ngOnInit() {
    this.login = localStorage.getItem('auth.login');
  }

}
