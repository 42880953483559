import { Component } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { BadgesComponent } from '../../layout/components/badges/badges.component';
import { CardInfoComponent } from '../../layout/components/card-info/card-info.component';
import { RouterLink } from '@angular/router';
import { SharedDataService } from '../../services/shared-data.service'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { CalendarComponent } from '../../layout/calendar/calendar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DatePipe, CommonModule  } from '@angular/common';
import { PaymentIntentsService } from '../../services/graphQl/payment-intents/payment-intents.service';
import { HttpClient } from '@angular/common/http';
import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';
import { take } from 'rxjs';
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Router } from '@angular/router';
import { NormalizeDataService } from '../../services/normalize-data.service';
import { environment } from "../../../environments/environment";

const { quickSightBaseUrl, quickSightDashboards } = environment;

@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [
    DataViewModule,
    BadgesComponent,
    RouterLink,
    CardInfoComponent,
    ScrollingModule,
    HttpClientModule,
    ApolloModule,
    CalendarComponent,
    NgxSkeletonLoaderModule,
    DatePipe,
    CommonModule
  ],
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.css'
})
export class SalesComponent {
  transactions: any[] = [];
  transactionsLoading: any[] = [{},{},{},{},{},{},{},{}];
  totalAmount: number;
  averageAmount : number;
  loader: boolean = true;
  fechaHoy: string;
  createdAtFrom: string = localStorage.getItem('calendar.created_at_from');
  createdAtTo: string = localStorage.getItem('calendar.created_at_to');
  formattedCreatedAtFrom: string = '';
  formattedcreatedAtTo: string = '';
  dashboard: QuicksightEmbedding.DashboardExperience;
  quickSightUrl: string;
  embeddingContext:EmbeddingContext;
  private windowId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  constructor(
    public sharedDataService: SharedDataService,
    private apollo: Apollo,
    private datePipe: DatePipe,
    private paymentIntentsService: PaymentIntentsService,
    private http: HttpClient,
    private router: Router,
    public normalizeDataService: NormalizeDataService
  ) {
    this.formattedCreatedAtFrom = this.datePipe.transform(localStorage.getItem('calendar.created_at_from'), 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(localStorage.getItem('calendar.created_at_to'), 'dd-MM-yyyy');
  }

  ngOnInit(): void {
    this.updateDate();
    this.GetDashboardURL();
    this.makeQueryPaymentIntent();
  }

  refreshTransactions() {
    this.loader = true;
    this.updateDate();
    this.GetDashboardURL();
    this.makeQueryPaymentIntent();
  }

  makeQueryPaymentIntent() {
    this.paymentIntentsService.queryPaymentIntent().subscribe((result: any) => {
      this.transactions = result?.data?.paymentIntents?.items;
      this.transactions = this.transactions.map(transaction => {
        return { ...transaction, updated_at: new Date(transaction.updated_at + 'UTC') };
      });
      console.log("Transacciones:", this.transactions)
      this.sharedDataService.transactions = result?.data?.paymentIntents?.items;
      // this.sortTransactionsByDate();

      this.loader = false;
    });
  }

  updateDate() {
    const createdAtFrom = new Date(localStorage.getItem('calendar.created_at_from'));
    const createdAtTo = new Date(localStorage.getItem('calendar.created_at_to'));

    // Suma un día a las fechas
    createdAtFrom.setDate(createdAtFrom.getDate() + 1);
    // createdAtTo.setDate(createdAtTo.getDate() + 1);

    // Formatear las fechas en el formato ('dd-MM-yyyy')
    this.formattedCreatedAtFrom = this.datePipe.transform(createdAtFrom, 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(createdAtTo, 'dd-MM-yyyy');

  }

  sortTransactionsByDate() {
    this.transactions = [...this.transactions].sort((a, b) => {
      const dateA = new Date(a.created_at_time_zone).getTime();
      const dateB = new Date(b.created_at_time_zone).getTime();
      return dateB - dateA;
    });
  }

  handleClickChannel(channel: string, id: string, operation_type: string, event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    } else {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    }
  }

  handleMouseDown(channel: string, id: string, operation_type: string, event: MouseEvent): void {
    if (event.button === 1) {
      localStorage.setItem(`channel_${id}`, channel + '/' + operation_type);
    }
  }



  public GetDashboardURL() {
    // this.quickSightUrl = localStorage.getItem('quickSightUrl');

    const createdAtFrom = this.formattedCreatedAtFrom.substring(6,10) + "-" +  this.formattedCreatedAtFrom.substring(3,5) + "-" + this.formattedCreatedAtFrom.substring(0,2);
    const createdAtTo = this.formattedcreatedAtTo.substring(6,10) + "-" + this.formattedcreatedAtTo.substring(3,5) + "-" + this.formattedcreatedAtTo.substring(0,2);
    const merchantId = localStorage.getItem('auth.merchant_id');
    const paramsUrl = "#p.merchantId=" + merchantId + "&p.createdAtFrom=" + createdAtFrom + "&p.createdAtTo=" + createdAtTo;
    let urlWithParams = "";
    console.log("createdAtFrom", createdAtFrom);
    console.log("createdAtTo", createdAtTo);
    if ( this.quickSightUrl == null){

      const authToken = localStorage.getItem('auth.access_token');
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        })
      };

      const dashboardId = quickSightDashboards.sales;
      this.http.get(`${quickSightBaseUrl}/${dashboardId}`,httpOptions)
        .pipe(
          take(1),
        )
        .subscribe((data: any) => {
            this.quickSightUrl = data.EmbedUrl;
            //localStorage.setItem('quickSightUrl',this.quickSightUrl);

            console.log("Quicksight:", data.EmbedUrl);
            urlWithParams =  data.EmbedUrl + paramsUrl;
            console.log("A-urlWithParams:", urlWithParams);
            this.Dashboard(this.quickSightUrl,merchantId,createdAtFrom,createdAtTo);
        });
    }else{
        this.Dashboard(this.quickSightUrl,merchantId,createdAtFrom,createdAtTo);
    }
  }

  public async Dashboard(embeddedURL: any,merchantId:string,createdAtFrom:string,createdAtTo:string) {
    var containerDiv = document.getElementById("dashboardContainer") || '';
    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "178px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: false,
    }
    this.embeddingContext = await createEmbeddingContext();
    if ( this.dashboard == null){
      this.dashboard = await this.embeddingContext.embedDashboard(frameOptions);
      console.log("Dashboard",this.dashboard);
    }else{
      this.dashboard.setParameters([
        {
          Name: 'merchantId',
          Values: [merchantId]
        },
        {
          Name: 'createdAtFrom',
          Values: [createdAtFrom]
        },
        {
          Name: 'createdAtTo',
          Values: [createdAtTo]
        }
      ]);
    }
  }



}
