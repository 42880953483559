import { Component, Input, ContentChild, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-card-summary',
  standalone: true,
  imports: [ NgTemplateOutlet ],
  templateUrl: './card-summary.component.html',
  styleUrl: './card-summary.component.css'
})
export class CardSummaryComponent {
  @ContentChild('titleTemplate')
  titleTemplate?: TemplateRef<any> = undefined;

  @Input()
  title: string = 'title';

  @Input()
  titleClass: string = 'text-start';

  @Input()
  contentClass: string = '';

  @Input()
  containerClass: string = '';

  @Input()
  hasTitle: boolean = true;
}
