<div class="flex flex-row w-full">
    <div class='sidebar h-screen py-6 px-6'>
        <app-sidebar></app-sidebar>
    </div>
    <div class='content flex flex-col py-10 px-6 w-full'>
        <div class='app-body flex-1'>
            <router-outlet></router-outlet>
        </div>
        <div class='app-footer self-end'>
            <app-footer></app-footer>
        </div>
    </div>
</div>