import { Component } from '@angular/core';
import { CardInfoComponent } from '../layout/components/card-info/card-info.component';
import { CardSummaryComponent } from '../layout/components/card-summary/card-summary.component';
import { CalendarComponent } from '../layout/calendar/calendar.component';
import { SkeletonModule } from 'primeng/skeleton';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedDataService } from '../services/shared-data.service';
import { DatePipe, CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';
import { take } from 'rxjs';
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { HttpClientModule, HttpHeaders } from '@angular/common/http'


const { quickSightBaseUrl, quickSightDashboards } = environment;

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CardInfoComponent,
    CardSummaryComponent,
    CalendarComponent,
    SkeletonModule,
    NgxSkeletonLoaderModule,
    DatePipe,
    CommonModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {

  merchantName: string;
  createdAtFrom: string = localStorage.getItem('calendar.created_at_from');
  createdAtTo: string = localStorage.getItem('calendar.created_at_to');
  formattedCreatedAtFrom: string = '';
  formattedcreatedAtTo: string = '';
  dashboard: QuicksightEmbedding.DashboardExperience;
  quickSightUrl: string;
  embeddingContext: EmbeddingContext;

  constructor(
    private sharedDataService: SharedDataService,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    this.formattedCreatedAtFrom = this.datePipe.transform(localStorage.getItem('calendar.created_at_from'), 'dd-MM-yyyy');
    this.formattedcreatedAtTo = this.datePipe.transform(localStorage.getItem('calendar.created_at_to'), 'dd-MM-yyyy');
  }

  public ngOnInit() {
    this.merchantName = localStorage.getItem('merchant_name');
    this.GetDashboardURL();
  }

  public GetDashboardURL() {
    // this.quickSightUrl = localStorage.getItem('quickSightUrl');

    const createdAtFrom = this.formattedCreatedAtFrom.substring(6, 10) + "-" + this.formattedCreatedAtFrom.substring(3, 5) + "-" + this.formattedCreatedAtFrom.substring(0, 2);
    const createdAtTo = this.formattedcreatedAtTo.substring(6, 10) + "-" + this.formattedcreatedAtTo.substring(3, 5) + "-" + this.formattedcreatedAtTo.substring(0, 2);
    const merchantId = localStorage.getItem('auth.merchant_id');
    const paramsUrl = "#p.merchantId=" + merchantId;
    let urlWithParams = "";
    console.log("createdAtFrom", createdAtFrom);
    console.log("createdAtTo", createdAtTo);

    const authToken = localStorage.getItem('auth.access_token');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      })
    };

    if (this.quickSightUrl == null) {
      const dashboardId = quickSightDashboards.dashboard;
      this.http.get(`${quickSightBaseUrl}/${dashboardId}`, httpOptions)
        .pipe(
          take(1),
        )
        .subscribe((data: any) => {
          this.quickSightUrl = data.EmbedUrl;
          //localStorage.setItem('quickSightUrl',this.quickSightUrl);

          console.log("Quicksight:", data.EmbedUrl);
          urlWithParams = data.EmbedUrl + paramsUrl;
          console.log("A-urlWithParams:", urlWithParams);
          this.Dashboard(urlWithParams, merchantId, createdAtFrom, createdAtTo);
        });
    } else {
      this.Dashboard(urlWithParams, merchantId, createdAtFrom, createdAtTo);
    }
  }

  public async Dashboard(embeddedURL: any, merchantId: string, createdAtFrom: string, createdAtTo: string) {
    var containerDiv = document.getElementById("dashboardContainer") as HTMLElement | null;


    // si ya existe un dashboard, destruirlo antes de crear uno nuevo
    if (this.dashboard) {
      console.log("Destruyendo dashboard existente...");
      try {
        await this.dashboard.reset(); // Resetea la instancia del dashboard
      } catch (error) {
        console.warn("Error al resetear el dashboard:", error);
      }
      this.dashboard = null;
    }
    // elimina manualmente el iframe anterior si existe
    while (containerDiv.firstChild) {
      containerDiv.removeChild(containerDiv.firstChild);
    }

    // evita la ejecución si el contenedor ya tiene un dashboard activo
    if (containerDiv.hasChildNodes()) {
      console.warn("El dashboard aún tiene nodos activos, evitando duplicación.");
      return;
    }


    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "771px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: false,
    }
    this.embeddingContext = await createEmbeddingContext();
    this.dashboard = await this.embeddingContext.embedDashboard(frameOptions);

    // if ( this.dashboard == null){
    //   this.dashboard = await this.embeddingContext.embedDashboard(frameOptions);
    //   console.log("",this.dashboard);

    // }else{
    //   this.dashboard.setParameters([
    //     {
    //       Name: 'merchantId',
    //       Values: [merchantId]
    //     },
    //     // {
    //     //   Name: 'createdAtFrom',
    //     //   Values: [createdAtFrom]
    //     // },
    //     // {
    //     //   Name: 'createdAtTo',
    //     //   Values: [createdAtTo]
    //     // }
    //   ]);
    // }
  }
}