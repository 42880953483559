<div class="sidebar flex flex-col w-[200px]  bg-gradient-to-b from-indigo-400 via-sky-400 to-cyan-400 rounded-[25px] shadow py-6">
    <!-- merchant info -->
    <div class="flex justify-center items-center flex-col mb-6">
        <img [src]="merchantLogo" class="merchantLogo"/>
        <img class="mt-2" src="../../../assets/img/ic-emoji.svg" />
        <div>
            <span class="text-white text-[10.27px] font-normal font-['Inter'] tracking-tight">¡Hola </span>
            <span class="text-white text-[10.27px] font-semibold font-['Inter'] tracking-tight">{{merchantName}}</span>
            <span class="text-white text-[10.27px] font-normal font-['Inter'] tracking-tight">! </span>
        </div>
    </div>
    <nav class="flex flex-col py-4">
        <a routerLink="/inicio" routerLinkActive="active " class="h-[50px] w-full text-white text-xs font-normal font-['Inter'] relative flex items-center ">
            <img class="inline mr-3 ms-5" src="../../../assets/img/ic-resumen-principal.svg" />
            Resumen Principal
        </a>
        <a id="transacciones" routerLink="/transacciones" routerLinkActive="active bg-indigo-400" class="h-[50px] w-full text-white text-xs font-normal font-['Inter'] relative flex items-center ">
            <img class="inline mr-3 ms-6" src="../../../assets/img/ic-ventas.svg" />
            Transacciones
        </a>
        <a routerLink="/abonos" routerLinkActive="active bg-indigo-400" class="h-[50px] w-full text-white text-xs font-normal font-['Inter'] relative flex items-center ">
            <img class="inline-block mr-3 ms-6" src="../../../assets/img/ic-abonos.svg" />
            Abonos
        </a>
    </nav>   

    <!-- logout -->
    <div class="flex justify-center items-center flex-col mt-auto">
        <div class="py-4">
            <a class="w-full cursor-pointer" (click)="onSingOut()" >
                <img class="inline mr-3" src="../../../assets/img/ic-logout.svg" />
                <span class="text-white text-xs font-normal font-['Inter'] inline">Cerrar sesión</span>
            </a>
        </div>
        <img src="../../../assets/img/ic-baaskit-white.svg" />
    </div>
</div>