import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { RouterLink, ActivatedRoute } from '@angular/router';
import { CardInfoComponent } from '../../layout/components/card-info/card-info.component';
import { CardToggleComponent } from '../../card-toggle/card-toggle.component';
import { GoogleMapComponent } from '../../layout/components/google-map/google-map.component';
import {RefundComponent} from '../../layout/components/refund/refund.component'
import {RefundSuccessComponent} from '../../layout/components/refund-success/refund-success.component'
import {RefundFailComponent} from '../../layout/components/refund-fail/refund-fail.component'
import { SharedDataService } from '../../services/shared-data.service'
import {SalesComponent} from '../sales/sales.component'
import { DatePipe } from '@angular/common';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaymentIntentsService } from '../../services/graphQl/payment-intents/payment-intents.service';
import { NormalizeDataService } from '../../services/normalize-data.service';
import { TooltipComponent } from '../../layout/components/tooltip/tooltip.component';

@Component({
  selector: 'app-sale-detail',
  standalone: true,
  imports: [ 
    RouterLink, 
    CardInfoComponent, 
    GoogleMapComponent, 
    RefundComponent, 
    RefundSuccessComponent,
    RefundFailComponent,
    CardToggleComponent,
    ApolloModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    DatePipe,
    TooltipComponent
  ],
  templateUrl: './sale-detail.component.html',
  styleUrl: './sale-detail.component.css'
})
export class SaleDetailComponent implements OnInit, OnDestroy {
  public transaction: any;
  public fechaHoraFormateada: string; 
  mostrarContenido: boolean = false;
  id: string;
  loader: boolean = true;
  channel: string;
  operation_type: string;

  tooltipTitle = 'En proceso de confirmación'

  constructor(
    public sharedDataService: SharedDataService, 
    private route: ActivatedRoute,
    private apollo: Apollo,
    private paymentIntentsService: PaymentIntentsService,
    public normalizeDataService: NormalizeDataService
    ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    let cadena = localStorage.getItem(`channel_${this.id}`);

    let partes = cadena.split("/");

    this.channel = partes[0];
    this.operation_type = partes[1];

    console.log("channel:", this.channel)
    console.log("operation_type:", this.operation_type)


    this.paymentIntentsService.queryPaymentIntentDetail(this.id).subscribe((result: any) => {
      this.transaction = result.data?.paymentIntentDetail;
      
      // Copiar el objeto para hacerlo mutable
      this.transaction = { ...this.transaction };

      this.transaction.updated_at = new Date(this.transaction.updated_at + 'UTC');
      this.transaction.updated_at.toString();

      this.transaction.created_at = new Date(this.transaction.created_at + 'UTC');
      this.transaction.created_at.toString();

      this.transaction.expired_at = new Date(this.transaction.expired_at + 'UTC');
      this.transaction.expired_at.toString();

      console.log("Detalle:", this.transaction)
      console.log("CHANNEL:", this.channel)
      this.loader = false;
      this.channel = this.transaction.channel;
    });
  }
  
  ngOnDestroy(): void {
    localStorage.removeItem(`channel_${this.id}`);
  }

  handleClick(): void {
    this.sharedDataService.setRefundButton(true);
    localStorage.setItem('refund.bin', this.transaction.bin);
    localStorage.setItem('refund.lastfourdigit', this.transaction.lastfourdigit);
    localStorage.setItem('refund.network', this.transaction.network);
    localStorage.setItem('refund.payment_id', this.id);
  }

  toggleContenido() {
    this.mostrarContenido = !this.mostrarContenido;
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event: Event) {
  //   // Limpiar el valor del localStorage al cerrar la pestaña
  //   localStorage.removeItem(`channel_${this.id}`);
  // }

  

}
