import { Component } from '@angular/core';
import { SharedDataService } from '../../../services/shared-data.service';
import { CardInfoComponent } from '../card-info/card-info.component';
import { FormsModule } from '@angular/forms';
import { SaleDetailComponent } from '../../../sales/sale-detail/sale-detail.component';
import { PortalService } from '../../../services/portal.service';
import {MatInputModule} from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-refund',
  standalone: true,
  imports: [
    CardInfoComponent, 
    FormsModule,
    MatInputModule,
    CommonModule
  ],
  templateUrl: './refund.component.html',
  styleUrl: './refund.component.css',
})
export class RefundComponent {
  inputValue = '';
  textValue = '';
  maxLimit: number = this.SaleDetailComponent.transaction.amount;
  monto: number;
  amountExceedsLimit: boolean = false;
  showErrorMessage: boolean = false;
  limitValue: boolean = false;
  loading: boolean = false;
  refundedAmount: number = this.SaleDetailComponent.transaction.refunded_amount;
  suma: number;
  diferencia: number = this.maxLimit - this.refundedAmount;
  isClicked = false;
  textLength: number = 0;
  numericValue: number = 0;

  constructor(
    private sharedDataService: SharedDataService,
    public SaleDetailComponent: SaleDetailComponent,
    private portalService: PortalService
  ) {
    this.isClicked = false;
    this.textLength = 0;
    this.numericValue = 0;
  }

  formatCurrency() {
    let numericValue = parseInt(this.inputValue.replace(/[^0-9]/g, ''), 10);

    if (isNaN(numericValue)) {
      this.inputValue = '';
      return;
    }

    // Formatea a peso
    this.inputValue = numericValue.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP',
    });
  }

  validateInput(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /[0-9]/;

    if (!pattern.test(inputChar) || this.inputValue.length >= 14) {
      // Evita la entrada si el carácter no es un número o se alcanzó el límite
      event.preventDefault();
    }  
  }

  isExceedingLimit(): boolean {
    this.numericValue = parseInt(this.inputValue.replace(/[^0-9]/g, ''), 10);
    this.suma = this.numericValue + this.refundedAmount;

    if (this.suma <= this.maxLimit ) {
      this.limitValue = true;
    } else {
      this.limitValue = false;
    }
    return this.suma > this.maxLimit;
  }

  validateTextarea(): void {
    this.textLength = this.textValue.length;

    if (this.textLength > 150) {
      this.showErrorMessage = true;
    } else {
      this.showErrorMessage = false;
    }
  }

  handleClick(flag: boolean): void {
    this.sharedDataService.setRefundButton(false);
    if(flag) {
      localStorage.removeItem('refund.bin');
      localStorage.removeItem('refund.lastfourdigit');
      localStorage.removeItem('refund.network');
    }
  }

  handleRefundFailComponent(): void {
    this.sharedDataService.setRefundFail(true);
  }

  handleRefundSuccessComponent(): void {
    this.sharedDataService.setRefundSuccess(true);
  }

  public makeRefund(): void {
    this.isClicked = true;
    if(this.limitValue && !this.showErrorMessage) {
      this.loading = true;
      const token = localStorage.getItem('auth.access_token');
      const merchant_id = localStorage.getItem('auth.merchant_id');
      const paymentintent_id = localStorage.getItem('refund.payment_id');
      const idempotency_key =  uuid();

      if (this.inputValue.trim() !== '') {
        // Obtener el monto desde el inputValue y asignarlo a la propiedad monto
        this.monto = parseFloat(this.inputValue.replace(/[^0-9]/g, ''));
        this.sharedDataService.amount = this.monto;
      }

      console.log("Monto:", this.monto);
      console.log("merchant_id:", merchant_id);

      this.portalService.RefundCreateStateMachine(this.monto, merchant_id, paymentintent_id, token, idempotency_key ).subscribe(
        (refund) => {
          this.loading = false;
          console.log(refund);
          this.handleClick(false);
          this.handleRefundSuccessComponent();
        },
        (error) => {
          this.loading = false;
          // Manejo de errores
          console.error(error);
          this.handleClick(false);
          this.handleRefundFailComponent();
        }
      );
    }
    
  }

  isButtonDisabled(): boolean {
    const disableByAmount = isNaN(this.numericValue) || !(typeof this.numericValue === 'number') || this.numericValue < 1;
    const disableByMotivText = this.textLength > 150;
    return disableByAmount || disableByMotivText || this.isClicked; 
  }
}
