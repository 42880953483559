import { Injectable } from '@angular/core';
import { Apollo, gql, ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepositsService {

  constructor(private apollo: Apollo,) { }

  public queryDeposits(): Observable<any> {

    const authToken = localStorage.getItem('auth.access_token');

    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);

    return this.apollo
      .query({
        query: gql`
          query Deposits($merchantId: String!, $createdAtFrom: String!, $createdAtTo: String!) {
            deposits(
              merchant_id: $merchantId
              deposit_date_from: $createdAtFrom
              deposit_date_to: $createdAtTo
            ) {
              items {
                deposit_date
                amount
                total_tax
                total_commission
                channel
                final_amount
              }
            }
          }
        `,
        variables: {
          merchantId: localStorage.getItem('auth.merchant_id'),
          createdAtFrom: localStorage.getItem('calendar.created_at_from'),
          createdAtTo: localStorage.getItem('calendar.created_at_to'),
        },
        context: {
          headers: headers,
        },
        fetchPolicy: 'network-only',
      })
  }

  public queryDepositDetail(depositDate: string, channel: string): Observable<any> {

    const authToken = localStorage.getItem('auth.access_token');

    const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);

    return this.apollo
      .query({
        query: gql`
          query DepositDetail($merchantId: String!, $depositDate: String!, $channel: String!) {
            depositDetail(
              merchant_id: $merchantId
              deposit_date: $depositDate
              channel: $channel
            ) {
              paymentIntents {
                id
                created_at
                amount
                final_amount
                status
                network
                bin
                lastfourdigit
                type
                updated_at
                refunded_amount
                total_taxes
                total_commissions
              }
            }
          }
        `,
        variables: {
          merchantId: localStorage.getItem('auth.merchant_id'),
          depositDate: depositDate,
          channel: channel
        },
        context: {
          headers: headers,
        },
        fetchPolicy: 'network-only',
      })
  }
}
