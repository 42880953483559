import { HttpClient, HttpInterceptorFn } from '@angular/common/http';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {jwtDecode} from 'jwt-decode';
import { environment } from "../environments/environment";

const { loginAzureUrl, tokenUrl } = environment;

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  console.log("req:",req);
  let httpClient = inject(HttpClient);
  let router = inject(Router);
  const token = localStorage.getItem('auth.access_token');
  const expiresOn = localStorage.getItem('auth.expires_on');
  const urlInseguras: any[]= [ tokenUrl ];

  if(urlInseguras.includes(req.url)) {
    return next(req);
  }

  if(token) {
    try {
      const isExpired =
      token && expiresOn
      ? parseFloat(expiresOn) < Date.now() / 1000
      : false;

      if(isExpired) {
        console.log('token expired');
        localStorage.removeItem('auth.access_token')
        const loginUrl = loginAzureUrl; // Your Azure B2C login URL
        window.location.href = loginUrl;
      } else {
        console.log('token not expired');
      }
    } catch (e) {
      console.log('invalid token');
      localStorage.removeItem('auth.access_token')
      const loginUrl = loginAzureUrl; // Your Azure B2C login URL
      window.location.href = loginUrl;
    }
  } else {
    console.log('no token');
    const loginUrl = loginAzureUrl; // Your Azure B2C login URL
    window.location.href = loginUrl;
  }

  return next(req);
};
