import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NormalizeDataService {

  constructor() { }

  private currencyConfig = {
    code: 'es-CL',
    prefix: ' CLP',
    options: { 
      style: 'currency', 
      currency: 'CLP'
    }
  };

  public currencyFormat(amount: any): string {
    return `${amount.toLocaleString(this.currencyConfig.code, this.currencyConfig.options)}${this.currencyConfig.prefix}`
  }

  public creditCardType(type: string): string {
    switch(type.toUpperCase()) {
      case 'DEBITO':
        return 'Débito'
      case 'CREDITO':
        return 'Crédito';
      default:
        return 'Prepago';
    }
  }

  public operationType(operationType: string): string {
    switch(operationType.toLowerCase()) {
      case 'purchase':
        return 'Venta'
      case 'refund':
        return 'Devolución'
      case 'reversal':
      default:
        return 'Reversa'
    }
  }

  public status(status: string): string {
    switch(status.toLowerCase()) {
      case 'succeeded':
      case 'success':
        return 'Aprobada'
      case 'error':
        return 'Rechazado'
      case 'processing':
      default:
        return 'Cancelado'
    }
  }

  public installments(transaction: any): string {
    if(transaction.type.toUpperCase() == 'CREDITO') {
      if(transaction.installment_count > 1) {
        return transaction.installment_count;
      } else {
        return 'Sin cuotas';
      }
    } else {
      return '-'
    }
  }

  public totalComission(transaction: any): any {
    if(transaction.total_taxes == null && transaction.total_commissions == null) {
      return { 
        hasValue: false,
        text: '-'
      }
    } else {
      const taxes = transaction.total_taxes ?? 0;
      const comission = transaction.total_commissions ?? 0;
      const total = taxes + comission;

      return { 
        hasValue: true,
        text: this.currencyFormat(total)
      }
    }
  }

  public finalAmount(transaction: any): any {
    if(transaction.final_amount == null) {
      return { 
        hasValue: false,
        text: '-'
      }
    } else {
      return { 
        hasValue: true,
        text: this.currencyFormat(transaction.final_amount)
      }
    }
  }

  public depositAmount(transaction: any): any {
    if(transaction.deposit_amount == null) {
      return { 
        hasValue: false,
        text: '-'
      }
    } else {
      return { 
        hasValue: true,
        text: this.currencyFormat(transaction.deposit_amount)
      }
    }
  }
}
