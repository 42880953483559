import { Component, Input, ContentChild, QueryList, ElementRef, TemplateRef} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-card-info-graphic',
  standalone: true,
  imports: [ NgTemplateOutlet, ],
  templateUrl: './card-info-graphic.component.html',
  styleUrl: './card-info-graphic.component.css'
})
export class CardInfoGraphicComponent {

  @ContentChild('titleTemplate')
  titleTemplate?: TemplateRef<any> = undefined;

  @Input()
  title: string = 'title';

  @Input()
  titleClass: string = 'text-start';

  @Input()
  contentClass: string = '';

  @Input()
  containerClass: string = '';

  @Input()
  mesActual: string = '';
  
  @Input()
  mesAnterior: string = '';

  @Input()
  hasTitle: boolean = true;

  ngOnInit() {
    console.log('ngOnInit!');
  }

}
