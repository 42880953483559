import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive  } from '@angular/router';
import { SharedDataService  } from '../../services/shared-data.service';
import { environment } from "../../../environments/environment";

const { loginAzureUrl } = environment;

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {

  merchantName: string;
  merchantLogo: string;

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService
  ) {}

  public ngOnInit() {
    this.merchantName = localStorage.getItem('merchant_name');
    this.merchantLogo = localStorage.getItem('merchant_logo');
  }

  public onSingOut() {
    localStorage.removeItem('auth.access_token')
    window.location.href = loginAzureUrl;
  }
}
