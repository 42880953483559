<!-- titulo -->
<section class="flex mb-4">
    <div class="flex-1 flex flex-col">
        <span class="span1 text-neutral-600 text-2xl font-semibold font-['Inter'] leading-normal">
            Información general
        </span>
        <span class="flex span2 text-neutral-600 text-sm font-light font-['Inter'] leading-tight">
            @if (formattedCreatedAtFrom === formattedcreatedAtTo) {
                <p>Estás visualizando datos del día <span style="font-weight: 600;">{{ formattedCreatedAtFrom }}</span></p>
            } @else {
                <p>Estás visualizando datos desde <span style="font-weight: 600;">{{ formattedCreatedAtFrom }}</span> hasta el <span style="font-weight: 600;">{{ formattedcreatedAtTo }}</span></p>
            }
        </span>
    </div> 
    <div class="flex gap-2">
        <app-calendar (rangeEvent)="refreshTransactions()"></app-calendar>
        <a (click)="refreshTransactions()" class="w-[50px] h-[37px] bg-white rounded-[58px] justify-center items-center inline-flex cursor-pointer ic-m">
            <img class="fill-neutral-600"  src="../../../assets/img/ic-table-refresh-gray.svg" />
        </a>
    </div>
</section>
<!-- box resumen
<div class="flex gap-x-5">
    <app-card-info-credit class="basis-[26%]" title="Próximo abono" titleClass="ps-6" contentClass="items-stretch" proximoAbono=" ">
        <div class="mx-5 mt-7 mb-1" style="width: 100%;">
            <div class="py-4 mb-9 px-7 cuadro pb-[7.71px] bg-white rounded-2xl shadow flex-col items-center gap-[2.29px] inline-flex" style="width: 100%;">
                <span class="text-slate-800 text-[11.41px] font-semibold font-['Inter'] leading-[13.14px]">MONTO DEL ABONO</span>
                <span class="text-neutral-600 text-[23.40px] font-semibold font-['Inter'] leading-[33.79px]">$1.350.000 CLP</span>
            </div>
            <div class="mx-12 mb-4">
                <button class="py-[7px] bg-indigo-500 rounded-md inline-flex relative flex items-center" style="width: 100%;">
                    <div class="text-white text-xs font-bold font-['Poppins'] mx-auto">Ventas por abonar</div>
                </button>
            </div>
        </div>
    </app-card-info-credit>

    <div class="flex flex-col basis-[26%]">
        <app-card-info-credit class="cuadro-1 flex-col inline-flex" title="Mes anterior" titleClass="ps-6" contentClass="items-stretch" mesAnterior=" ">
            <div class="mx-2 text-left" style="width: 100%;">
                <div class="pl-2 mb-1">
                    <span class="text-slate-800 text-[8.67px] font-semibold font-['Inter'] leading-[8.86px]">MONTO TOTAL DE ABONOS</span>
                </div>
                <div class="pl-4 cuadro pt-1 pb-[7.71px] bg-white rounded-2xl shadow flex items-center justify-between" style="width: 100%;">
                    <span class="text-slate-800 text-[17.67px] font-semibold font-['Inter'] leading-snug">$1.350.000 CLP</span>
                    <img class="inline pr-4" src="../../../assets/img/ic-credit-1.svg" alt="" style="margin-right: 4px;">
                </div>
                             
            </div>
        </app-card-info-credit>
        <app-card-info-credit class="cuadro-1 flex-col inline-flex" title="Mes actual" titleClass="ps-6" contentClass="items-stretch" mesActual=" ">
            <div class="mx-2 text-left" style="width: 100%;">
                <div class="pl-2 mb-1">
                    <span class="text-slate-800 text-[8.67px] font-semibold font-['Inter'] leading-[8.86px]">MONTO TOTAL DE ABONOS</span>
                </div>
                <div class="pl-4 cuadro pt-1 pb-[7.71px] bg-white rounded-2xl shadow flex items-center justify-between" style="width: 100%;">
                    <span class="text-slate-800 text-[17.67px] font-semibold font-['Inter'] leading-snug">$1.350.000 CLP</span>
                    <img class="inline pr-4" src="../../../assets/img/ic-credit-2.svg" alt="" style="margin-right: 4px;">
                </div>
                             
            </div>
        </app-card-info-credit>
    </div>
    <app-card-info-graphic class="basis-[48%]" title="Abonos últimos 12 meses" titleClass="ps-6" contentClass="items-stretch">
        <img class="px-4 pb-1" src="../../../assets/img/ic-graphic.svg" alt="" style="width: 100%; height: 175px;">
    </app-card-info-graphic>
</div> -->

<!-- <div class="flex-1" id="dashboardContainer"></div> -->

<!-- titulo abonos -->
<section class="flex mb-6 flex-col">
    <div class="flex mb-7">
        <span class="flex-1 text-neutral-600 text-2xl font-semibold font-['Inter'] leading-normal">
            Abonos generados
        </span>
        @if (loader) {
            <div class="inline-flex">
                <div role="status" class="pr-4">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-indigo-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                </div>
    
                <div class="text flex gap-2 text-indigo-400 text-[14px] font-semibold font-['Poppins'] leading-none tracking-tight " style="align-items: center;">
                    Estamos cargando la información de tus abonos.
                </div>
            </div>
        }
    </div>
    <div class="flex flex-col">
        <div class="borde-1 bg-white py-3 text-center shadow-1 mb-4 flex ">
            <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal border-e-2 border-indigo-50">
                Monto abonado
            </span>
            <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal border-e-2 border-indigo-50">
                Fecha
            </span>
            <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal border-e-2 border-indigo-50">
                Comisión
            </span>
            <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal border-e-2 border-indigo-50">
                Monto de ventas
            </span>
            <!-- <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal border-e-2 border-indigo-50">
                Canal transaccional
            </span> -->
            <!-- <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Inter'] leading-normal ">
                Ver detalles
            </span> -->
        </div>

        @if (loader) {
            <p-dataView #dv [value]="creditsLoading" [rows]="5" [paginator]="true">
                <ng-template let-creditsLoading pTemplate="list">
                    @for (item of creditsLoading; track item.id) {
                        <div class="borde-2 bg-white py-3 text-center items-center shadow-1 mb-2 flex">
                            <span class="loading px-3 flex-1 border-e-2 border-indigo-50">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span>
                            <span class="loading px-3 flex-1  border-e-2 border-indigo-50">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span>
                            <span class="loading px-3 flex-1  border-e-2 border-indigo-50">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span>
                            <span class="loading px-3 flex-1  border-e-2 border-indigo-50">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span>
                            <!-- <span class="loading px-3 flex-1  border-e-2 border-indigo-50">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span> -->
                            <!-- <span class="loading px-3 flex-1 ">
                                <ngx-skeleton-loader [theme]="{cursor: 'auto'}"></ngx-skeleton-loader>
                            </span> -->
                        </div>
                    }
                    
                </ng-template>
            </p-dataView>
        } @else {
            <p-dataView #dv [value]="credits" [rows]="5" [paginator]="true">
                <ng-template let-credits pTemplate="list">
                    @for (item of credits; track item.id) {
                        <div class="borde-2 bg-white py-3 text-center items-center shadow-1 mb-2 flex">
                            <span class="flex-1 text-indigo-400 text-sm font-medium font-['Inter'] leading-normal border-e-2 border-indigo-50">
                                {{ normalizeDataCreditService.currencyFormat(item.final_amount) }}
                            </span>
                            <span class="flex justify-center flex-1 inline text-slate-600 text-[10px] font-normal font-['Poppins'] leading-normal border-e-2 border-indigo-50">
                                <img src="../../../assets/img/ic-calendar.2.svg" class="mr-2"/>
                                <div style="display: flex; align-items: center;">
                                    {{ item.deposit_date | date: 'dd-MM-yyyy' }}
                                </div>
                            </span>
                            <span class="flex-1 text-slate-600 text-[10px] font-normal font-['Poppins'] leading-normal border-e-2 border-indigo-50">
                                @if (normalizeDataCreditService.totalComission(item).hasValue) {
                                    {{ normalizeDataCreditService.totalComission(item).text }}
                                } @else {
                                    {{ normalizeDataCreditService.currencyFormat(0) }}
                                }
                            </span>
                            <span class="flex-1 text-slate-600 text-[10px] font-normal font-['Poppins'] leading-normal border-e-2 border-indigo-50">
                                {{ normalizeDataCreditService.currencyFormat(item.amount) }}
                            </span>
                            <!-- <span class="flex-1 text-slate-600 text-[10px] font-normal font-['Poppins'] leading-normal border-e-2 border-indigo-50">
                                {{item.channel}}
                            </span> -->
                            <!-- <span class="flex-1 text-neutral-600 text-xs font-semibold font-['Poppins'] leading-normal">
                                <a [routerLink]="'/abonos/' + item.deposit_date + '/' + item.channel" class="text-indigo-400 text-[10px] font-medium font-['Poppins'] leading-none tracking-tight">
                                    Ver detalles
                                    <img class="inline ms-1" src="../../../assets/img/sales/ic-arrow-detalle.svg" />
                                </a>
                            </span> -->
                        </div>
                    }
                </ng-template>
            </p-dataView>
        }

    </div>
</section>